import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Card,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Grid,
  Paper,
  Tabs,
  Tab,
  TablePagination
} from '@mui/material';
import Layout from './layout';
import axios from 'axios';
import { Chart } from 'react-google-charts';
import SDGChart from './sdgChart';
import { fetchUserDetails } from '../../hooks/fetchUserDetails';
import useUserInnovation from '../../hooks/userInnovations';
import GhanaMap from './map';
import PieChart from './PieChart';

const Dashboard = () => {
  const [listOfInnovations] = useUserInnovation();
  const [pendingInnovations, setPendingInnovations] = useState([]);
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [stats, setStats] = useState({
    approvedCount: 0,
    rejectedCount: 0,
    pendingCount: 0,
  });
  const [tabIndex, setTabIndex] = useState(0); 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

 
  useEffect(() => {
    const fetchAllUserDetails = async () => {
        const details = {};
        for (const innovation of listOfInnovations) {
            if (innovation.userId && !details[innovation.userId]) {
                try {
                    details[innovation.userId] = await fetchUserDetails(innovation.userId);
                } catch (error) {
                    console.error(`Error fetching details for user ${innovation.userId}:`, error);
                }
            }
        }
        setUserDetails(details);
    };

    fetchAllUserDetails();
}, [listOfInnovations]);

  useEffect(() => {
    const fetchPendingInnovations = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get('https://api.ghsdginnovations.org/api/admin/innovations', config);
        const pendingInnovations = response.data.filter(innovation =>
          !innovation.approved && !innovation.rejected && !innovation.archived
        );
        setPendingInnovations(pendingInnovations);
      } catch (error) {
        console.error('Error fetching pending innovations:', error);
      }
    };

    fetchPendingInnovations();
  }, []);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const [approvedResponse, rejectedResponse, pendingResponse] = await Promise.all([
          axios.get('https://api.ghsdginnovations.org/api/admin/innovations/approved/count', config),
          axios.get('https://api.ghsdginnovations.org/api/admin/innovations/rejected/count', config),
          axios.get('https://api.ghsdginnovations.org/api/admin/innovations/pending/count', config),
          axios.get('https://api.ghsdginnovations.org/api/admin/innovations/archived/count', config),
        ]);

        setStats({
          approvedCount: approvedResponse.data.count,
          rejectedCount: rejectedResponse.data.count,
          pendingCount: pendingResponse.data.count,
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    fetchStatistics();
  }, []);

  const handleAction = async (id, action) => {
    const token = localStorage.getItem('token');
    const endpoint = `https://api.ghsdginnovations.org/api/admin/innovations/${id}/${action}`;

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} the innovation`);
      }

      setMessage(`Innovation ${action}d successfully`);
      setOpenSnackbar(true);

      const updatedPendingInnovations = pendingInnovations.filter(innovation => innovation._id !== id);
      setPendingInnovations(updatedPendingInnovations);
    } catch (error) {
      setMessage(`Error ${action}ing innovation: ${error.message}`);
      setOpenSnackbar(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const pieChartData = [
    ['Status', 'Count'],
    ['Pending', stats.pendingCount],
    ['Approved', stats.approvedCount],
    ['Rejected', stats.rejectedCount],
  ];

  const pieChartOptions = {
    title: '',
    is3D: false,
    colors: ['#f0b27a', '#196f3d', '#d35400'],
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const paginatedInnovations = pendingInnovations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <Layout>
      <Box marginTop='64px' marginBottom='30px'>
        <Box
          style={{
            borderRadius: '12px',
            border: '2px solid #ccc',
            padding: '10px',
            margin: '10px',
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Dashboard Tabs"
            style={{ marginBottom: '20px' }}
          >
            <Tab label="Status and Region" />
            <Tab label="Innovations by SDGs" />
          </Tabs>

          <Grid container spacing={2}>
            {/* Conditional Rendering based on Tab Index */}
            {tabIndex === 0 && (
              <>
                <Grid item xs={12} md={6}>
                 
                  <PieChart/>
                
                </Grid>

                <Grid item xs={12} md={6}>
                <Typography>
                  Innovations by Regions
                </Typography>
               
                  <GhanaMap />
                </Grid>
              </>
            )}

            {tabIndex === 1 && (
              <Grid item xs={6} md={12}>
              
                <SDGChart />
              </Grid>
            )}
          </Grid>
        </Box>

        <Box
          marginTop={{ xs: '24px', md: '64px' }}
          marginBottom={{ xs: '50px', md: '20px' }}
          paddingBottom="20px"
          overflowY="scroll"
          maxHeight="calc(100vh - 124px)"
        >
          {/* Pending Innovations Table */}
          {pendingInnovations.length === 0 ? (
            <Typography
              variant="h6"
              sx={{ color: '#0468B1', display: 'flex', alignItems: 'center', textAlign: 'center', padding: '16px' }}
            >
              No pending innovations.
            </Typography>
          ) : (
            <TableContainer component={Paper} elevation={1}>
       <Table>
  <TableHead>
    <TableRow sx={{ backgroundColor: '#0468B1' }}>
      <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
        Innovation Name
      </TableCell>
      <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
        Submitted by
      </TableCell>
      <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
        Innovator
      </TableCell>
      <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
        Status
      </TableCell>
      <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
        Actions
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {paginatedInnovations.map((innovation) => (
      <TableRow
        key={innovation._id}
        sx={{
          cursor: 'default',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          <Typography sx={{ color: '#0468B1' }}>
            <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              {innovation.innovationName}
            </Link>
          </Typography>
        </TableCell>
        <TableCell>
          {userDetails[innovation.userId]?.fullName}
        </TableCell>
        <TableCell>
          <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {innovation.firstName && innovation.lastName
              ? `${innovation.firstName} ${innovation.lastName}`
              : userDetails[innovation.userId]?.fullName || 'Unknown'}
          </Link>
        </TableCell>
        <TableCell>
          <span style={{ color: 'orange' }}>Pending</span>
        </TableCell>
        <TableCell>
          <FormControl>
            <Select
              value=""
              onChange={(event) => handleAction(innovation._id, event.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Action</MenuItem>
              <MenuItem value="approve">Approve</MenuItem>
              <MenuItem value="reject">Reject</MenuItem>
              <MenuItem value="archive">Archive</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>


        <TablePagination
        rowsPerPageOptions={[3]}
        component="div"
        count={pendingInnovations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
    
          )}

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={message}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Dashboard;
