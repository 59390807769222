import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../components/Logo";
import { Input, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../pages/login/style.css";
import axios from "../../helpers/axios";
import { authConstants } from "../../actions/constants";
import { Box, styled } from "@mui/system";
import { Card, Button, Stack, TextField } from '@mui/material'
import swal from 'sweetalert'


const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}));

styled('img')(() => ({
  width: '100%',
}));

const JWTRegister = styled(JustifyBox)(() => ({
  backgroundImage: `url('path_to_your_background_image.jpg')`, // Replace with your background image path
  backgroundSize: 'cover',
  minHeight: '100vh !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .card': {
    maxWidth: 500,
    borderRadius: 12,
    background: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background for card
 
    textAlign: 'center',
  },
}));




const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(`/auth/login`, {
        email,
        password,
      });
      const { token, userId, role } = res.data;
  
      // Check if user has admin role
      if (role !== 'superAdmin') {
        await swal('Login Failed', 'You do not have permission to access ONLY ADMIN ACCESS.', 'error');
        return;
      }
    
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          userId,
        },
      });
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      await swal('Login Failed', 'There was an error during login', 'error');
 // Reload the page after showing the error
 setTimeout(() => {
  window.location.reload();
}, 2000); // Adjust delay if needed
} finally {
setIsLoading(false);
}
  };
  
  
  return (
    <JWTRegister>
      <Card className="card text-center">
       
      <Box p={4} height="100%">
  <div className="d-flex align-items-center justify-content-center mb-4">
    <Box component="img" src="https://digitalx.undp.org/images/630478c456d6a9a3e003e9f2_logo-blue.svg" sx={{ width: 74, height: 130 }} />
  </div>

  <h4 style={{ marginBottom: '10px' }}>Sign In</h4>

  <Form onSubmit={userLogin} style={{ marginTop: '16px' }}>
    <Stack spacing={4}>
      <TextField
        className='form-control'
        placeholder='Email'
        value={email}
        type="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        className='form-control'
        placeholder='Password'
        value={password}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      />
    </Stack>

    <div className="form-group" style={{ marginTop: '50px' }}>
      <Button
        type="submit"
        sx={{ textTransform: 'capitalize', marginRight: 2 }}
        disabled={isLoading}
      >
        {isLoading ? 'Please wait...' : 'Login'}
      </Button>
      |
      <Button
        sx={{ textTransform: 'capitalize', color:"orange" }}
        onClick={() => navigate("//")}
      >
        Forgot password? Reset
      </Button>
    </div>
  </Form>
</Box>

       
      </Card>
    </JWTRegister>
  );
};

export default Login;
